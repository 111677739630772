import C from "../../components/sidebar/StandardSidebar.module.css";

import { unixToDate } from "@warrenio/api-spec/conversion";
import { Badge } from "../../components/Badge.tsx";
import { WTooltip } from "../../components/WTooltip.tsx";
import { localeCompareBy } from "../../components/data/comparers.ts";
import { getFilteredItems } from "../../components/data/getFilteredItems.ts";
import { getSortedItems, type SimpleSortField } from "../../components/data/getSortedItems.ts";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { CurrencyBalance } from "../../components/l10n/Currency.tsx";
import { EmptyItem, NoResults } from "../../components/sidebar/EmptyItem.tsx";
import { SidebarList, type SidebarListState } from "../../components/sidebar/SidebarList.tsx";
import { SkeletonBlock, StandardBlock } from "../../components/sidebar/StandardSidebar";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { ResourceCreateLinks } from "../api/resourceCreateLinks.ts";
import { BillingAccountIcon, ShortInfoList } from "./BillingAccountInfo.tsx";
import { eBillingAccountQueryAtom } from "./billingAccountQuery.ts";
import type { EBillingAccount } from "./billingLogic.tsx";
import { billingAccountLink } from "./links.ts";

const sortFields = [
    {
        id: "name",
        title: "Name",
        compare: localeCompareBy((i) => i.title),
    },
    { id: "created", title: "Created Date", getValue: (i) => unixToDate(i.account.created).getTime() },
    { id: "unpaid_amount", title: "Outstanding Amount", getValue: (i) => i.unpaidAmount ?? 0 },
] satisfies SimpleSortField<EBillingAccount>[];

export function BillingList() {
    return (
        <SidebarList
            data={(props) => <BillingData {...props} />}
            title="Billing"
            sortFields={sortFields}
            actionButton={{
                title: "Create New Billing Account",
                action: ResourceCreateLinks.billing_account,
            }}
            listId="billing"
        />
    );
}

export default BillingList;

export function BillingData(props: SidebarListState) {
    const data = useSuspenseQueryAtom(eBillingAccountQueryAtom);
    const isPending = false;
    // TODO: proper atoms + queries etc

    return (
        <>
            {/*<QueryErrorBlocks query={query} />*/}
            <BillingDataInner {...props} isPending={isPending} data={[...data.values()]} />
            {isPending && <SkeletonBlock />}
        </>
    );
}

interface BillingInnerProps extends SidebarListState {
    data: EBillingAccount[];
    isPending: boolean;
}

function BillingDataInner({ queryString, sortId, sortAsc, data, isPending }: BillingInnerProps) {
    let billingItems = getFilteredItems(queryString, [...data.values()], (item) => [item.title]);
    billingItems = getSortedItems(sortFields, { sortId, sortAsc }, billingItems);

    const billingList = billingItems.map((obj) => <BillingBlock key={obj.id} item={obj} />);

    const empty = (
        <EmptyItem icon="jp-stack-icon">
            Create a billing account first, then you can start creating resources.
        </EmptyItem>
    );

    return billingList.length ? (
        billingList
    ) : queryString ? (
        <NoResults />
    ) : isPending ? /* If we are still loading, we can not know if we are empty, so just show nothing */ null : (
        empty
    );
}

export function BillingBlock({ item }: { item: EBillingAccount }) {
    const { title, isDefault, unpaidAmount, ongoingAmount } = item;

    return (
        <StandardBlock link={billingAccountLink(item)}>
            <div className={C.StandardBlockIcon}>
                <BillingAccountIcon account={item} />
            </div>

            <div className={cn(C.StandardBlockName, "text-ellipsis")}>
                <b className="text-ellipsis">{title}</b>
                <ShortInfoList account={item} />
            </div>

            {!!unpaidAmount && (
                <div className={C.StandardBlockInfo}>
                    <div className="text-muted">Unpaid</div>
                    <span className="color-error font-size-default">
                        <CurrencyBalance value={-unpaidAmount} />
                    </span>
                </div>
            )}

            <div className={C.StandardBlockInfo}>
                <div className="text-muted">Ongoing</div>
                <span className={ongoingAmount > 0 ? "color-success" : ""}>
                    <CurrencyBalance value={ongoingAmount} />
                </span>
            </div>

            {isDefault && (
                <div className={C.holder}>
                    <WTooltip text="Default">
                        <Badge noDot iconOnly inList>
                            <MaskIcon className="jp-icon-checkmark size-0.75rem" />
                        </Badge>
                    </WTooltip>
                </div>
            )}
        </StandardBlock>
    );
}
